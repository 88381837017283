<template>
  <main class="main-content main-content-bg mt-0" >
    <div
      class="page-header min-vh-100"
      style="
        background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-basic.jpg');
      "
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container mb-4">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-7 mt-2">
            <div class="card border-0 mb-0">
              <div class="card-header bg-transparent">
                <h5 class="text-dark text-center mt-2 mb-3">Login com:</h5>
                <div class="btn-wrapper text-center">
                  <a
                    href="javascript:;"
                    class="btn btn-neutral btn-icon btn-sm mb-0"
                  >
                    <img
                      class="w-30"
                      src="../../../assets/img/logos/google.svg"
                    />
                    Google
                  </a>
                </div>
              </div>
              <div class="card-body px-lg-5 pt-0">
                <div class="text-center text-muted mb-4">
                  <small>Ou Login com credenciais: </small>
                </div>
                <!-- <form role="form" class="text-start"> -->
                  <div class="mb-3">
                    <argon-input
                      id="email"
                      type="text"
                      placeholder="E-mail ou usuário"
                      aria-label="Email"
                      @input-value="usuarioArewGE = $event"
                    />
                  </div>
                  <div class="mb-3">
                    <argon-input
                      id="password"
                      type="password"
                      placeholder="Senha"
                      aria-label="Password"
                      @input-value="senhaArewGE = $event"
                    />
                  </div>
                  <p class="text-sm mt-0 mb-0 text-end">
                    Esqueceu a 
                    <router-link
                      :to="{ name: 'Reset Basic' }"
                      class="text-dark font-weight-bolder"
                    >
                    senha?
                    </router-link>
                  </p>

                  <argon-switch id="rememberMe" name="rememberMe">
                    Lembrar-se
                  </argon-switch>

                  <argon-alert v-show="apareceErroVazio" color="danger" class="my-4 mb-0">
                    <strong>Erro!</strong>
                    <p class="text-sm mx-2">{{msgpopup}}</p>
                  </argon-alert>

                  <argon-alert v-show="apareceSucesso" color="success" class="my-4 mb-0 translate-x-32">
                    <strong>Sucesso!</strong>
                    <p class="text-sm mx-2">{{msgpopup}}</p>
                  </argon-alert>
                  
                  <div class="text-center">
                    <argon-button
                      color="success"
                      variant="gradient"
                      full-width
                      class="my-4 mb-2"
                      @click="efetuarLogin"
                    >Login</argon-button>
                  </div>
                  <div class="mb-2 position-relative text-center">
                    <p
                      class="text-sm font-weight-bold mb-2 text-secondary text-border d-inline z-index-2 bg-white px-3"
                    >
                      ou
                    </p>
                  </div>
                  <div class="text-center">
                    <argon-button 
                      color="dark"
                      variant="gradient"
                      full-width
                      class="mt-2 mb-4"
                      @click="cadastraCliente"
                      >Cadastrar-se</argon-button
                    >
                  </div>
                <!-- </form> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>

<script>
import ArgonAlert from "@/components/ArgonAlert.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { mapMutations } from "vuex";
import CryptoJS from 'crypto-js';
import axios from 'axios';

export default {
  name: "SigninBasic",
  components: {
    ArgonAlert,
    AppFooter,
    ArgonInput,
    ArgonSwitch,
    ArgonButton
  },
  data() {
    return {
      usuarioArewGE: '',
      senhaArewGE: '',
      apareceErroVazio : false,
      apareceSucesso : false,
      msgpopup : '',
    }
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
  },
  methods: {
    ...mapMutations(["toggleDefaultLayout"]),
    cadastraCliente() {
      this.$router.push('/authentication/signup/basic');
    },
    efetuarLogin() {
      if (this.usuarioArewGE == '' || this.senhaArewGE == '') {
        localStorage.clear();  
        this.msgpopup = 'Preencha todos os campos, por favor!' ;
        this.apareceErroVazio = true;
        this.apareceSucesso = false;
        return;
      } else {
        axios.post('http://127.0.0.1:8080/usuario/LoginEcommerce', {
          LoginUsuario : this.usuarioArewGE,
          LoginSenha :  this.senhaArewGE
        }).then(response => {
          let data = response.data;

          this.msgpopup = data.message;

          if (data.success) {
            if(document.getElementById('rememberMe').checked == true) {
              localStorage.lembrarse = true;   
            } else {
              localStorage.removeItem('lembrarse');  
            }

            localStorage.logado = data.cacheLogin; //Armazeno no navegador se o usuário está logado. Importante!!!! Variável = TRUE;          
            this.apareceErroVazio = false;
            this.apareceSucesso = true;

            if (data.dadosUsuario && data.dadosUsuario.PessoaID) {
              this.ciphertext = CryptoJS.AES.encrypt('000' + data.dadosUsuario.PessoaID, '$uehtaMod@rgASç');

              localStorage.aPDsIs1oe0  = this.ciphertext.toString(); //Guardar pessoaID para consultas futuras
            }

            //Essas duas linhas de baixo é um exemplo de como descriptografar os dados do usuário (PESSOAID)
            // const preDesc = CryptoJS.AES.decrypt(localStorage.getItem('aPDsIs1oe0'), '$uehtaMod@rgASç');
            // this.decryptedText = preDesc.toString(CryptoJS.enc.Utf8);

            this.$router.push('/dashboards/dashboard-default');
          } else {
            localStorage.clear();                          
            this.apareceErroVazio = true;
            this.apareceSucesso = false;
          }
        }).catch(error => {
          this.msgpopup = error.message;
          this.apareceErroVazio = true;
          this.apareceSucesso = false;
        })
      }
    }
  },
};
</script>