<template>
  <div id="alertaPadraoArew" class="alertaPadraoArew">
    <div class="popUpAviso">
        <div class="cabecalhoPopUp bg-gradient-info">
          <div><b>{{title}}</b></div>
          <div><i class="ni ni-fat-remove" @click="NConfirmaSair"></i></div>
        </div>
        <div class="conteudoPopUp">
           {{message}}
        </div>
        <div class="botoesPopUp">
          <argon-button color="success" size="md" variant="gradient" @click="ConfirmaSair">{{campoConfirma}}</argon-button>
          <argon-button color="danger" size="md" variant="gradient" @click="NConfirmaSair">{{ campoNConfirma }}</argon-button>
        </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";

export default {
  name: "PopUpPadrao",
  components: {
    ArgonButton
  },
  props: {
    campoNConfirma: {
      type: String,
      default: "",
    },
    campoConfirma: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: ""
    },
    message: {
      type: String,
      default: ""
    },
  },
  emits : ['fecha-pop-up'],
  data(){
    return {
      aparecetela : false
    }
  },
  mounted() {
      var divFilha = document.getElementById('alertaPadraoArew');
      var divPai = divFilha.parentNode;
      var divAvo = divPai.parentNode;

      divAvo.insertBefore(divFilha, divPai);
  },
  methods: {
    ConfirmaSair() {
      this.aparecetela = false;
      localStorage.clear();
      location.reload();
      this.$emit('fecha-pop-up', this.aparecetela);
    },
    NConfirmaSair() {
      this.aparecetela = false;
      this.$emit('fecha-pop-up', this.aparecetela);
    }
  },
};
</script>
