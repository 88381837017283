<template>
  <main class="main-content main-content-bg mt-0">
    <div
      class="page-header min-vh-100"
      style="
        background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/lock-basic.jpg');
      "
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-7">
            <div class="card py-lg-3">
              <div class="card-body text-center">
                <h4 class="mb-0 font-weight-bolder">Redefinir senha</h4>
                <p class="mb-4">Nova senha para usuário de: {{ entidade }}.</p>
                <!-- <form role="form"> -->
                  <argon-input
                    id="senharecuper1"
                    type="password"
                    placeholder="Nova senha"
                    aria-label="password"
                    title="Senha deve conter no mínimo 8 caracteres."
                    @input-value="novasenha1 = $event"
                  />
                  <argon-input
                    id="senharecuper2"
                    type="password"
                    placeholder="Confirme a sua nova senha"
                    title="Senha deve conter no mínimo 8 caracteres."
                    @input-value="novasenha2 = $event"
                  />

                  <div>
                    <small>
                      <em>
                        Obs: senha deve conter no mínimo <b>8 caracteres</b>;<br> 
                      </em>
                    </small>
                  </div>

                  <argon-alert v-show="apareceErroVazio" color="danger" class="my-4 mb-0">
                    <strong>Erro!</strong>
                    <p class="text-sm mx-2">{{msgpopup}}</p>
                  </argon-alert>

                  <argon-alert v-show="apareceSucesso" color="success" class="my-4 mb-0 translate-x-32">
                    <strong>Sucesso!</strong>
                    <p class="text-sm mx-2">{{msgpopup}}</p>
                  </argon-alert>

                  <div class="text-center pt-3">
                    <argon-button
                      size="lg"
                      full-width
                      color="dark"
                      variant="gradient"
                      class="mb-0"
                      @click="confirmaAlteracaoSenha"
                      >Atualizar</argon-button
                    >
                  </div>
                <!-- </form> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>

<script>
import AppFooter from "@/examples/PageLayout/Footer.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonAlert from "@/components/ArgonAlert.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { mapMutations } from "vuex";
import axios from "axios";

export default {
  name: "LockBasic",
  components: {
    AppFooter,
    ArgonButton,
    ArgonInput,
    ArgonAlert 
  },
  data() {
    return {
      apareceComponent: false,
      novasenha1 : '',
      novasenha2 : '',
      apareceErroVazio : false,
      apareceSucesso : false,
      msgpopup : '',
      usuarioId : '',
      entidade : '',
    }
  },
  watch: {
    novasenha1(senha){
      this.apareceErroVazio = false;
      this.apareceSucesso = false;
      this.msgpopup = ''; 
      document.getElementById('senharecuper2').classList.remove('is-invalid');
      document.getElementById('senharecuper2').classList.remove('is-valid');

      if(senha.length < 8) {
        this.apareceErroVazio = true;
        this.apareceSucesso = false;
        this.msgpopup = 'A senha precisa ter no mínimo 8 caracteres.'
        document.getElementById('senharecuper1').classList.add('is-invalid');
      } 
      else if (senha == this.novasenha2) {
        this.apareceErroVazio = false;
        this.apareceSucesso = true;
        this.msgpopup = 'Senha confirmada. Atualize para sua nova senha no botão abaixo!'
        document.getElementById('senharecuper2').classList.remove('is-invalid');
        document.getElementById('senharecuper2').classList.add('is-valid');
      } 
      else if (senha !== this.novasenha2 && this.novasenha2.length > 0) {
        this.apareceErroVazio = true;
        this.apareceSucesso = false;
        this.msgpopup = 'Senhas divergentes!'
        document.getElementById('senharecuper2').classList.add('is-invalid');
        document.getElementById('senharecuper2').classList.remove('is-valid');
      } 
      else {
        this.apareceErroVazio = false;
        document.getElementById('senharecuper1').classList.remove('is-invalid');
      }
    },
    novasenha2(senha) {
      this.apareceErroVazio = false;
      this.apareceSucesso = false;
      this.msgpopup = ''; 
      document.getElementById('senharecuper1').classList.remove('is-invalid');
      document.getElementById('senharecuper1').classList.remove('is-valid');

      if(senha.length < 8) {
        this.apareceErroVazio = true;
        this.apareceSucesso = false;
        this.msgpopup = 'A senha precisa ter no mínimo 8 caracteres.'
        document.getElementById('senharecuper2').classList.add('is-invalid');
        document.getElementById('senharecuper2').classList.remove('is-valid');
      } 
      else if (senha === this.novasenha1) {
        document.getElementById('senharecuper2').classList.remove('is-invalid');
        document.getElementById('senharecuper2').classList.add('is-valid');
      }
      else if (senha !== this.novasenha1) {
        this.apareceErroVazio = true;
        this.apareceSucesso = false;
        this.msgpopup = 'Senhas divergentes.'
        document.getElementById('senharecuper2').classList.add('is-invalid');
        document.getElementById('senharecuper2').classList.remove('is-valid');
      }
      else {
        this.apareceErroVazio = false;
        document.getElementById('senharecuper2').classList.remove('is-invalid');
        document.getElementById('senharecuper2').classList.remove('is-valid');
      }      
    }
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
  },
  mounted() {
    if (localStorage.getItem('id') === null || localStorage.getItem('id') === false) {
      this.$router.push('/authentication/reset/basic');
      return;
    }

    this.usuarioId = localStorage.getItem('id');
    localStorage.removeItem('id');     
    this.entidade = localStorage.getItem('ent');
    localStorage.removeItem('ent');
  },
  methods: {
    ...mapMutations(["toggleDefaultLayout"]),
    confirmaAlteracaoSenha() {
        this.apareceErroVazio = false;
        this.apareceSucesso = false;
        this.msgpopup = ''; 


        if((this.novasenha1 === this.novasenha2) && this.novasenha1.length >= 8 && this.novasenha2.length >= 8) {
          this.apareceSucesso = true;
          this.msgpopup = 'Senha alterada com sucesso. Aguarde e efetue o login novamente!'; 

          axios.post('http://127.0.0.1:8080/usuario/alterarSenha', {
            PessoaID : this.usuarioId,
            NovaSenha2 : this.novasenha2
          }).then(response=> {
            
              let data = response.data;
              if(data.success) {
                this.apareceErroVazio = true;
                this.apareceSucesso = false;
                this.msgpopup = data.message; 

                this.$router.push('/authentication/signin/basic');
              } else {
                this.apareceErroVazio = true;
                this.apareceSucesso = false;
                this.msgpopup = 'Algo deu errado. Volte a página e tente novamente!'; 
              }
          }).catch(error => {
              this.apareceErroVazio = true;
              this.apareceSucesso = false;
              this.msgpopup = error.message; 
          }); 
        } else {
          this.apareceErroVazio = true;
          this.apareceSucesso = false;
          this.msgpopup = 'Senhas divergentes, ou não possuem 8 caracteres!'; 
        }
    }
  },
};
</script>
