<template>
  <main class="main-content main-content-bg mt-0">
    <div
      class="page-header min-vh-100"
      style="
        background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/reset-basic.jpg');
      "
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-7">
            <div class="card z-index-0 mb-7">
              <div class="card-header text-center pt-4 pb-1">
                <h4 class="font-weight-bolder mb-1">Resetar senha: </h4>
                <p class="mb-0">
                  Digite seu e-mail abaixo
                </p>
              </div>
              <div class="card-body">
                <!-- <form role="form"> -->
                  <argon-input
                    id="email"
                    type="email"
                    placeholder="E-mail"
                    aria-label="Email"
                    @input-value="emailUsuario = $event"
                  />

                  <argon-alert v-show="apareceErroVazio" color="danger" class="my-4 mb-0">
                    <strong>Erro!</strong>
                    <p class="text-sm mx-2">{{msgpopup}}</p>
                  </argon-alert>

                  <argon-alert v-show="apareceSucesso" color="success" class="my-4 mb-0 translate-x-32">
                    <strong>Sucesso!</strong>
                    <p class="text-sm mx-2">{{msgpopup}}</p>
                  </argon-alert>

                  <div class="text-center">
                    <argon-button
                      color="dark"
                      variant="gradient"
                      class="my-4 mb-2"
                      full-width
                      @click="verificarEmailUsuario"
                      >Enviar</argon-button
                    >
                  </div>
                  <p class="text-sm mt-0 mb-0 text-center">
                    Efetuar login
                    <router-link
                      :to="{ name: 'Signin Basic' }"
                      class="text-dark font-weight-bolder"
                    >
                    novamente
                    </router-link>
                  </p>
                <!-- </form> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>

<script>
import AppFooter from "@/examples/PageLayout/Footer.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonAlert from "@/components/ArgonAlert.vue";
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";
import axios from "axios";

export default {
  name: "ResetBasic",
  components: {
    AppFooter,
    ArgonInput,
    ArgonButton,
    ArgonAlert,
  },
  data() {
    return {
      emailUsuario: '',
      apareceErroVazio : false,
      apareceSucesso : false,
      msgpopup : ''
    }
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();
    body.classList.remove("bg-gray-200");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
    body.classList.add("bg-gray-200");
  },
  methods: {
    ...mapMutations(["toggleDefaultLayout"]),
    verificarEmailUsuario() {
      if (this.emailUsuario == '' || this.emailUsuario.indexOf('@') == -1 || this.emailUsuario.indexOf('.com') == -1) {
        this.msgpopup = 'Preencha o campo corretamente com o e-mail do usuário, por favor. Se o problema persistir, entre em contato com o suporte!';
        this.apareceErroVazio = true;
        this.apareceSucesso = false;
      }  else {

        axios.get('http://127.0.0.1:8080/usuario/RecSenhaEcommerce?emailUsuario=' +  this.emailUsuario)
        .then(response => {
          let data = response.data;
          
          this.msgpopup = data.message;

          if(data.success == true) {
            this.apareceErroVazio = false;
            this.apareceSucesso = true;
            
            this.$router.push('/authentication/verification/basic');
          } else {
            this.apareceErroVazio = true;
            this.apareceSucesso = false;
          }

        }).catch(error => {
          this.msgpopup = error.message;
          this.apareceErroVazio = true;
          this.apareceSucesso = false;
        });
      }
    }
  },
};
</script>
