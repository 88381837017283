<template>
  <navbar />
  <div
    id="sobre"
    class="page-header position-relative"
    :style="{
      height: '700px',
      backgroundImage:
        'url(' + require('../../assets/img/pricing-header-bg.jpg') + ')',
      backgroundSize: 'cover',
    }"
  >
    <span class="mask bg-gradient-info opacity-8"></span>
    <div class="container pb-5 pt-7 postion-relative z-index-2">
      <div class="row">
        
        <div class="mx-auto text-center col-md-6 mt-2 mt-md-6">
          <h3 class="text-white">Uma solução de gestão empresarial para TODAS as suas necessidades!</h3>
          <p class="text-white">
            Com o ArewGE o empresário é capaz de saber em tempo real as notas emitidas, pedidos sendo lançados, contas a pagar e a receber sendo lançadas e baixadas, bem como novos clientes que estão entrando no sistema.
          </p>
        </div>
        <div class="mx-auto text-center col-md-6">
          <iframe width="100%" height="315" src="https://www.youtube.com/embed/bdrOoxmXxNM?si=FAxyu-xcLbqt2wwg" title="Arew Sistemas - Software" frameborder="0" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
  <div class="container my-6">
    <div class="row">
      <div class="mx-auto text-center col-8">
        <h6 class="opacity-5">Mais de <b>50 marcas</b> tem o nosso Software!</h6>
      </div>
    </div>
    <div class="mt-4 row">
      <div class="mb-4 col-lg-2 col-md-4 col-6">
        <img
          class="w-80 opacity-9"
          src="../../assets/img/logos/gray-logos/ARural.png"
          alt="logo_coinbase"
        />
      </div>
      <div class="mb-4 col-lg-2 col-md-4 col-6">
        <img
          class="w-80 opacity-9"
          src="../../assets/img/logos/gray-logos/Micateck.png"
          alt="logo_nasa"
        />
      </div>
      <div class="mb-4 col-lg-2 col-md-4 col-6">
        <img
          class="w-80 opacity-9"
          src="../../assets/img/logos/gray-logos/Lula.png"
          alt="logo_netflix"
        />
      </div>
      <div class="mb-4 col-lg-2 col-md-4 col-6">
        <img
          class="w-80 opacity-9"
          src="../../assets/img/logos/gray-logos/Sardinha.png"
          alt="logo_pinterest"
        />
      </div>
      <div class="mb-4 col-lg-2 col-md-4 col-6">
        <img
          class="w-80 opacity-9"
          src="../../assets/img/logos/gray-logos/Yazigi.png"
          alt="logo_spotify"
        />
      </div>
      <div class="mb-4 col-lg-2 col-md-4 col-6">
        <img
          class="w-80 opacity-9"
          src="../../assets/img/logos/gray-logos/zagoPiscinas.png"
          alt="logo_vodafone"
        />
      </div>
    </div>
    <div id="duvidasFrequentes" class="mt-5 row duvidasFrequentes">
      <div class="mx-auto text-center col-md-6">
        <h2>Dúvidas frequentes</h2>
        <p>
          As perguntas mais frequentes que recebemos por aqui. Demais dúvidas, entre em contato com nosso suporte! 
        </p>
      </div>
    </div>
    <div class="row">
      <div class="mx-auto col-md-10">
        <div id="accordionRental" class="accordion">
          <accordion-item
            accordion-id="headingOne"
            collapse-id="collapseOne"
            active
          >
            <template #question>O que preciso para ter o sistema?</template>
            <template #answer>
              Tudo que você precisa é de um computador que tenha processador core i3 ou superior e que tenha no mínimo 4GB de memória RAM,
              navegador atualizado e <b>acesso à internet</b>.
            </template>
          </accordion-item>
          <accordion-item accordion-id="headingTwo" collapse-id="collapseTwo">
            <template #question>Tenho algum limite de tela e/ou usuários?</template>
            <template #answer>
              Não! Você pode ter diversos usuários e acessar a plataforma de diversos computadores sem pagar nada a mais por isso, o que 
              permite que você faça o gerenciamento da sua operação e gestão da sua empresa de qualquer lugar.
            </template>
          </accordion-item>

          <accordion-item
            accordion-id="headingThree"
            collapse-id="collapseThree"
          >
            <template #question
              >Qual horário de funcionamento?</template
            >
            <template #answer>
              Nosso time está disponível de segunda à sexta das 08:00 as 18:00.
            </template>
          </accordion-item>

          <accordion-item accordion-id="headingFour" collapse-id="collapseFour">
            <template #question>O sistema emite nota fiscal?</template>
            <template #answer>
              Sim, o sistema pode emitir CF-e (cupom fiscal), NFC-e (nota fiscal consumidor eletrônica em cupom) e NF-e DANFE (nota fiscal eletrônica). Recurso disponível apenas na versão <b>Premium</b> ou <b>Enterprise</b>.
            </template>
          </accordion-item>

          <accordion-item
            accordion-id="headingFifth"
            collapse-id="collapseFifth"
          >
            <template #question>Vocês fazem o cadastro ou importação de dados? </template>
            <template #answer>
              Nosso time realiza qualquer importação de dados para o nosso sistema, todavia não realizamos os cadastros, e você não estará sozinho.
              Todos os cadastros e configurações do nosso sistema serão feitos de maneira assistida por nossos colaboradores, que lhe dará 
              dicas e sugestões de como organizar as informações para um melhor funcionamento da sua empresa.
            </template>
          </accordion-item>
        </div>
      </div>
    </div>
  </div>
 <!-- <div
    class="page-header position-relative"
    :style="{
      backgroundImage:
        'url(' + require('../../assets/img/pricing-header-bg.jpg') + ')',
      backgroundSize: 'cover',
    }"
  >
    <span class="mask bg-gradient-info opacity-6"></span>
    <div id="precos" class="container pb-10 pb-lg-9 pt-7 postion-relative z-index-2">
      <div class="row">
        <div class="mx-auto text-center col-md-6 mt-4">
          <h3 class="text-white">COMPARE E COMPRE</h3>
          <p class="text-white">
            Junte-se à Arew Sistemas e faça a escolha certa!
          </p>
        </div>
      </div>
      <div class="row">
        <div class="mx-auto text-center col-lg-4 col-md-6 col-7">
          <div class="mt-5 nav-wrapper position-relative z-index-2">
            <ul
              id="tabs-pricing"
              class="flex-row p-1 nav nav-pills nav-fill"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  id="tabs-iconpricing-tab-1"
                  class="mb-0 nav-link active"
                  data-bs-toggle="tab"
                  href="#monthly"
                  role="tab"
                  aria-controls="monthly"
                  aria-selected="false"
                  >Mensal</a
                >
              </li>
              <li class="nav-item">
                <a
                  id="tabs-iconpricing-tab-2"
                  class="mb-0 nav-link"
                  data-bs-toggle="tab"
                  href="#trimestral"
                  role="tab"
                  aria-controls="trimestral"
                  aria-selected="true"
                  >Trimestral</a
                >
              </li>
              <li class="nav-item">
                <a
                  id="tabs-iconpricing-tab-3"
                  class="mb-0 nav-link"
                  data-bs-toggle="tab"
                  href="#annual"
                  role="tab"
                  aria-controls="annual"
                  aria-selected="false"
                  >Anual</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!--<div class="mt-n8">
    <div class="container">
      <div class="tab-content tab-space">
        <div id="monthly" class="tab-pane active">
          <div class="row">
            <div class="mb-4 col-lg-4 mb-lg-0">
              <pricing-card
                badge="Starter"
                :price="{ currency: 'R$', value: '59' }"
                :specifications="[
                  { label: '2 team members', includes: true },
                  { label: '20GB Cloud storage', includes: true },
                  { label: 'Integration help', includes: false },
                  { label: 'Sketch Files', includes: false },
                  { label: 'API Access', includes: false },
                  { label: 'Complete documentation', includes: false },
                ]"
                :action="{
                  color: 'dark',
                  route: 'javascript:;',
                  label: 'Contratar',
                }"
              />
            </div>
            <div class="mb-4 col-lg-4 mb-lg-0">
              <pricing-card
                badge="premium"
                :price="{ currency: 'R$', value: '89' }"
                :specifications="[
                  { label: '10 team members', includes: true },
                  { label: '40GB Cloud storage', includes: true },
                  { label: 'Integration help', includes: true },
                  { label: 'Sketch Files', includes: true },
                  { label: 'API Access', includes: false },
                  { label: 'Complete documentation', includes: false },
                ]"
                :action="{
                  color: 'success',
                  route: 'javascript:;',
                  label: 'Contratar',
                }"
              />
            </div>
            <div class="mb-4 col-lg-4 mb-lg-0">
              <pricing-card
                badge="Enterprise"
                :price="{ currency: 'R$', value: '99' }"
                :specifications="[
                  { label: 'Unlimited team members', includes: true },
                  { label: '100GB Cloud storage', includes: true },
                  { label: 'Integration help', includes: true },
                  { label: 'Sketch Files', includes: true },
                  { label: 'API Access', includes: true },
                  { label: 'Complete documentation', includes: true },
                ]"
                :action="{
                  color: 'dark',
                  route: 'javascript:;',
                  label: 'Contratar',
                }"
              />
            </div>
          </div>
        </div>
        <div id="trimestral" class="tab-pane">
          <div class="row">
            <div class="mb-4 col-lg-4 mb-lg-0">
              <pricing-card
                badge="Starter"
                :price="{ currency: 'R$', value: '1000' }"
                :specifications="[
                  { label: '2 team members', includes: true },
                  { label: '20GB Cloud storage', includes: true },
                  { label: 'Integration help', includes: false },
                  { label: 'Sketch Files', includes: false },
                  { label: 'API Access', includes: false },
                  { label: 'Complete documentation', includes: false },
                ]"
                :action="{
                  color: 'dark',
                  route: 'javascript:;',
                  label: 'Contratar',
                }"
              />
            </div>
            <div class="mb-4 col-lg-4 mb-lg-0">
              <pricing-card
                badge="premium"
                :price="{ currency: 'R$', value: '1000' }"
                :specifications="[
                  { label: '10 team members', includes: true },
                  { label: '40GB Cloud storage', includes: true },
                  { label: 'Integration help', includes: true },
                  { label: 'Sketch Files', includes: true },
                  { label: 'API Access', includes: false },
                  { label: 'Complete documentation', includes: false },
                ]"
                :action="{
                  color: 'success',
                  route: 'javascript:;',
                  label: 'Contratar',
                }"
              />
            </div>
            <div class="mb-4 col-lg-4 mb-lg-0">
              <pricing-card
                badge="Enterprise"
                :price="{ currency: 'R$', value: '1000' }"
                :specifications="[
                  { label: 'Unlimited team members', includes: true },
                  { label: '100GB Cloud storage', includes: true },
                  { label: 'Integration help', includes: true },
                  { label: 'Sketch Files', includes: true },
                  { label: 'API Access', includes: true },
                  { label: 'Complete documentation', includes: true },
                ]"
                :action="{
                  color: 'dark',
                  route: 'javascript:;',
                  label: 'Contratar',
                }"
              />
            </div>
          </div>
        </div>
        <div id="annual" class="tab-pane">
          <div class="row">
            <div class="mb-4 col-lg-4 mb-lg-0">
              <pricing-card
                badge="Starter"
                :price="{ currency: 'R$', value: '119' }"
                :specifications="[
                  { label: '2 team members', includes: true },
                  { label: '20GB Cloud storage', includes: true },
                  { label: 'Integration help', includes: false },
                  { label: 'Sketch Files', includes: false },
                  { label: 'API Access', includes: false },
                  { label: 'Complete documentation', includes: false },
                ]"
                :action="{
                  color: 'dark',
                  route: 'javascript:;',
                  label: 'Contratar',
                }"
              />
            </div>
            <div class="mb-4 col-lg-4 mb-lg-0">
              <pricing-card
                badge="premium"
                :price="{ currency: 'R$', value: '159' }"
                :specifications="[
                  { label: '10 team members', includes: true },
                  { label: '40GB Cloud storage', includes: true },
                  { label: 'Integration help', includes: true },
                  { label: 'Sketch Files', includes: true },
                  { label: 'API Access', includes: false },
                  { label: 'Complete documentation', includes: false },
                ]"
                :action="{
                  color: 'success',
                  route: 'javascript:;',
                  label: 'Contratar',
                }"
              />
            </div>
            <div class="mb-4 col-lg-4 mb-lg-0">
              <pricing-card
                badge="Enterprise"
                :price="{ currency: 'R$', value: '399' }"
                :specifications="[
                  { label: 'Unlimited team members', includes: true },
                  { label: '100GB Cloud storage', includes: true },
                  { label: 'Integration help', includes: true },
                  { label: 'Sketch Files', includes: true },
                  { label: 'API Access', includes: true },
                  { label: 'Complete documentation', includes: true },
                ]"
                :action="{
                  color: 'dark',
                  route: 'javascript:;',
                  label: 'Contratar',
                }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="mt-4">
    <div class="mx-auto text-center col-md-6">
      <p>
        Se dentro de 30 dias você não estiver satisfeito, realizamos a devolução do valor pago.
      </p>
      <h6>
        Compre e comprove o que é ter a Arew Sistemas ao seu lado!
      </h6>
    </div>
  </div>
  <div class="row justify-content-center align-items-center w-100">
    <div class="col-lg-3 col-md-12 p-3 col-12 text-center">
      <img :src="imgLoc" class="w-30" alt="garantia">
    </div>
    <div class="col-lg-3 col-md-12 p-3 col-12 text-center">
      <img :src="img30d" class="w-20" alt="garantia">
    </div>
    <div class="col-lg-3 col-md-12 p-3 col-12 text-center mt-2 mt-md-2">
      <img :src="imgSec" class="w-20" alt="sslSeguro">
    </div>
    <div class="col-lg-3 col-md-12 p-3 col-12 text-center">
      <img :src="imgBli" class="w-30" alt="garantia">
    </div>
  </div>
  <div class="mt-4">
    <div id="empresa" class="mx-auto text-center col-md-6">
      <hr>
      <h2 class="text-black">DESTAQUES</h2>
      <h4 class="text-gray mb-4 d-none d-md-block">
        Conheça alguns de nossos clientes.
      </h4>
      <h4 class="text-gray d-block d-md-none">
        Mais sobre a Arew Sistemas.
      </h4>
      <div class="row w-100 text-center">
          <div class="col py-4 px-4 ">
            <carousel
              :items="[
                {
                  img: image2,
                  title: 'O maior medalhista paralímpico do Brasi, Daniel Dias',
                  description:
                    'é cliente Arew Sistemas desde 2020.',
                  icon: {
                    component: 'ni ni-camera-compact text-dark',
                    background: 'bg-white'
                  }
                },
                {
                  img: image1,
                  title: 'Aline da Nina Granolas.',
                  description:
                    'As melhores granolas com gestão empresarial apurada desde 2022.',
                  icon: {
                    component: 'ni ni-bulb-61 text-dark',
                    background: 'bg-white'
                  }
                },
                {
                  img: image3,
                  title: 'Dona Nélia da 3F Super Troca de Óleo.',
                  description:
                    'Cliente Arew Sistemas desde 2008.',
                  icon: {
                    component: 'ni ni-trophy text-dark',
                    background: 'bg-white'
                  }
                },
                {
                  img: image4,
                  title: 'Carla Roncoletta da New Koriums!',
                  description:
                    'Cliente Arew Sistemas desde 2020.',
                  icon: {
                    component: 'ni ni-trophy text-dark',
                    background: 'bg-white'
                  }
                },
                {
                  img: image5,
                  title: 'Temos clientes a mais de 32 anos!',
                  description:
                    'Sr. Oscar da Cardoso Organização Contábil de Joanópolis - SP, cliente desde 1991.',
                  icon: {
                    component: 'ni ni-trophy text-dark',
                    background: 'bg-white'
                  }
                }
              ]"
            />
          </div>
          <p class="text-gray px-5">
            Com diversos clientes, você encontra o melhor software para o seu negócio atendendo desde o varejo até a indústria, com uma plataforma 100% online. 
            Consulte nossos vendedores para mais informações.
          </p>
        </div>
    </div>
  </div>
  
  <app-footer />
  <div
    class="page-header position-relative"
    :style="{
      backgroundImage:
        'url(' + require('../../assets/img/newsletter.jpg') + ')',
      backgroundSize: 'cover',
    }"
  >
    <span class="mask bg-gradient-info opacity-6"></span>
    <div id="contatos" class="container pb-4 pb-lg-4 postion-relative z-index-2">
      <div class="row">
        <div class="mx-auto text-center col-md-6 mt-4">
          <h3 class="text-white">Fique por dentro das novidades!</h3>
          <p class="text-white">
            Receba e-mails, <b>promoções</b> e mensagens personalizadas.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="mt-4 mx-auto text-center col-lg-12 col-md-12 col-12">
          <div class="nav-wrapper position-relative z-index-2 py-2">
            <argon-input class="pb-2" type="text" placeholder="Digite o seu nome - ex: Akira Sato" title="Digite o seu nome - ex: Akira Sato" @input-value="nomePessoa = $event" />
            <argon-input class="pb-2" type="email" placeholder="Digite seu e-mail - ex: cliente@exemplo.com" title="Digite seu e-mail - ex: cliente@exemplo.com" @input-value="emailPessoa = $event" />
            <argon-input class="pb-2" type="number" placeholder="Número de telefone - ex: fixo -> 1144128530 ou Celular -> 11972317718" title="Número de telefone - ex: (DDD) 91234-1234" @input-value="numeroPessoa = $event" />
            
            <argon-alert v-show="apareceErroVazio" color="danger" class="my-2 mb-4">
              <strong>Erro!</strong>
              <p class="text-sm mx-2">{{msgpopup}}</p>
            </argon-alert>

            <argon-alert v-show="apareceSucesso" color="success" class="my-2 translate-x-32 mb-4">
              <strong>Sucesso!</strong>
              <p class="text-sm mx-2">{{msgpopup}}</p>
            </argon-alert>
            
            <argon-button color="success" size="lg" full-width title="Enviar dados" @click="enviaDadosEmail">Enviar</argon-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-4">
    <div class="mx-auto text-center col-md-6 py-2">
      <h2 class="text-black">Quem é Sérgio Akira Sato?</h2>
      
      <div class="mx-auto text-center col-md-6 py-4">
        <a href="https://buscatextual.cnpq.br/buscatextual/visualizacv.do?id=K4248273A6&tokenCaptchar=03ADUVZwAbUwxOyoh7_YSvbpbIWUV7JmF8uA8JfdHYCvTu0oR4wtXJIdgybFPaL4iXRSUMgQC-fil93jONLWQ3jsd3bsy9sWnQp8ZXSN-2_46HKZ97j9Q7r8icnOE4Esf60MxLqmCyJ2G5EcdKU2tRK-TrMelntXE6cRo-79qvdpsWi_n1IkBDoT5HzHLqzQjSUQ58aTXJONqXfxaxMMPfeqNlDarjjwNdcYa4iV_eGtIKnAxQQ_7uqXnTa1CNzqZdsPvk2CmwzFjalIfilPYBBqzYWstz3hR7Xc38g1FYu_0SGR47vsAiuNrROQeN6t2NS39bTvKXQnzBvgp6oiMPWzHaOS83pIz3LnK1i9NRHVIkYS7fUy5aFjb8oykN2uZ51EENH_pRXKhsgYq8vykChtZmtj6cT8uu78Lyw8B5Os6SC1GqolQnqoy1axa9INDJnWRUXAr0Hg0U2-riV-d_d-hPDHa_WrYYzmrphvCPvkxDdwg2cbhWT9_MTPajYIHP8usdccxnwvTZ_kdu9PLMLB1_gsP65-mnM4HdGgKSauZvCH-mmleBlZOfK9HPDjdnk95XfihXicik" target="_blank">
          <img :src="imgAki" class="w-50" style="border-radius: 100%" alt="Akira Sato, quem é?" />
        </a>
      </div>

      <div class="row w-100 text-center">
        <p class="text-gray px-5">
            Profissional experiente em tecnologia de ponta no desenvolvimento de softwares com domínio em ferramentas de gestão, sistemas de rastreamento por GPS e rádio frequência, <b>empresário</b> desde 1988, <b>fundador</b> da Arew Sistemas, técnico em agricultura, <b>matemático</b>, <b>analista de sistemas</b>, <b>professor</b> universitário desde 2001 na graduação desde 2009 na pós graduação.
            <br><br><b>Mestre em engenharia mecânica pela Unicamp</b> na área de concentração em gestão industrial por processos de negócios.
            <br><br>Foi <b>diretor de Sistemas Administrativos na Prefeitura da Estância de Atibaia</b> integrando os postos de saúde, escolas, Câmara, paço municipal e prédio administrativo de 2001 a 2005.
            <br><br><b>Co-fundador</b> da empresa InFormaSeg Sistemas Inteligentes de Riscos.
          </p>
        </div>
    </div>
  </div>

</template>
<script>
import Navbar from "@/examples/PageLayout/PrecosMenu.vue";
// import PricingCard from "./components/PricingCard.vue"; 
import AppFooter from "@/examples/PageLayout/Footer.vue";
import AccordionItem from "./components/AccordionItem.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import Carousel from "../../views/dashboards/components/CarouselPricing.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonAlert from "@/components/ArgonAlert.vue";
// import InfoCard from "@/examples/Cards/InfoCardPricing.vue";
// import ArgonBadge from "@/components/ArgonBadge.vue";

import image2 from "../../assets/img/clientes/Daniel-Dias-Arew.jpg";
import image1 from "../../assets/img/clientes/Aline-NinaGranolas-Arew.jpg";
import image3 from "../../assets/img/clientes/Nelia-Arew.jpg";
import image4 from "../../assets/img/clientes/Carla-Koriums-Arew.jpg";
import image5 from "../../assets/img/clientes/Oscar-Arew-1991.jpeg";

import img30d from "../../assets/img/selos/selo_garantia_30dias.png";
import imgSec from "../../assets/img/selos/ssl_sec_100.png";
import imgBli from "../../assets/img/selos/site_blindado.png";
import imgLoc from "../../assets/img/selos/site_lock.png";
import imgEbi from "../../assets/img/selos/selo_ebit.png";



import imgAki from "../../assets/img/AkiraSato.jpg";

export default {
  
  name: "Pricing",
  components: {
    Navbar,
    // PricingCard,
    AppFooter,
    AccordionItem,
    Carousel,
    ArgonInput,
    ArgonButton,
    ArgonAlert,
    // InfoCard,
    // ArgonBadge,
  },
  beforeRouteUpdate(to, from, next) {
    // Verifica se a nova rota contém 'arewsistemas'.
    if (to.path.indexOf('arewsistemas') !== -1) {
      // Se a página foi recarregada, redireciona para a raiz.
      if (this.isPageReloaded) {
        next('/');
      } else {
        // Continua a navegação normalmente.
        next();
      }
    } else {
      // Continua a navegação normalmente.
      next();
    }
  },
  data() {
    return {
      isPageReloaded : false,
      image2,
      image1,
      image3,
      image4,
      image5,
      imgSec,
      img30d,
      imgBli,
      imgEbi,
      imgLoc,
      imgAki,
      apareceErroVazio : false,
      apareceSucesso : false,
      msgpopup : '',
      nomePessoa: '',
      emailPessoa: '',
      numeroPessoa: '',
      online: true,
    };
  },
  mounted() {
    window.chatbaseConfig = {
      chatbotId: "b4AC2RwQAl6EdtymG-iTk",
    }
    // Adiciona o script do Google Tag Manager
    const gtagScript = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-ES5CW4D5S2';
    gtagScript.onload = () => {
      // Configuração do Google Tag Manager
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-ES5CW4D5S2');
    };
    document.head.appendChild(gtagScript);

    // Adiciona o script do Clarity
    const clarityScript = document.createElement('script');
    clarityScript.async = true;
    clarityScript.type = 'text/javascript';
    clarityScript.src = 'https://www.clarity.ms/tag/k9wv68qe9h';
    document.head.appendChild(clarityScript);

    this.$store.state.showSidenav = false;
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true ;
    this.$store.state.hideConfigButton = true;
    setNavPills();

    this.isPageReloaded = true;
  },
  beforeUnmount() {
    this.$store.state.showSidenav = true;
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
    if (this.$store.state.isPinned === false) {
      const sidenav_show = document.querySelector(".g-sidenav-show");
      sidenav_show.classList.remove("g-sidenav-hidden");
      sidenav_show.classList.add("g-sidenav-pinned");
      this.$store.state.isPinned = true;
    }
  },
  methods: {
    enviaDadosEmail() {
      this.apareceErroVazio = false;
      this.apareceSucesso = false;

      if (this.emailPessoa == '' || this.nomePessoa == '' || this.numeroPessoa == '') {
        this.msgpopup = 'Preencha todos os campos, por favor!';
        this.apareceErroVazio = true;
        this.apareceSucesso = false;
        return;
      } else {
        this.nomePessoa = this.nomePessoa.replace(/[^a-zA-Z 0-9]+/g, '');
        
        if (this.emailPessoa.indexOf('@') == -1 || this.emailPessoa.indexOf('.com') == -1) {
          this.msgpopup = 'E-mail inválido!';
          this.apareceErroVazio = true;
          this.apareceSucesso = false;
          return;
        } else if (this.numeroPessoa.length < 10 || this.numeroPessoa.length > 11 ) {
            this.msgpopup = 'Senha precisa ter no mínimo 10 caracteres! ex: fixo -> 1144128530 ou Celular -> 11972317718.';
            this.apareceErroVazio = true;
            this.apareceSucesso = false;
          
          return; 
        } else {
          this.msgpopup = 'Dados Validados com sucesso!';
          this.apareceErroVazio = false;
          this.apareceSucesso = true;
          return; 
        }

        //  axios.post('http://127.0.0.1:8080/usuario/CriarNewUser', {
        //    usuario : this.novoUsuario,
        //    senha :  this.novaSenha,
        //    email : this.emailNovo
        //  }).then(response => {
        //    let data = response.data;
        //     this.msgpopup = data.message; 
        //     if (data.success) {
        //       this.apareceErroVazio = false;
        //       this.apareceSucesso = true;        //       this.rotaAposTime();
        //     } else {
        //       localStorage.clear();
        //       this.apareceErroVazio = true;
        //       this.apareceSucesso = false;
        //     }
        //  }).catch(error => {
        //    this.msgpopup = error.message;
        //    this.apareceErroVazio = true;
        //    this.apareceSucesso = false;
        //  })
      }
    }
  }
};
</script>