<template>
  <main class="main-content mt-0">
    <div
      class="page-header min-vh-100"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/erro404.jpg') + ')',
      }"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-7 mx-auto text-center">
            <h1 class="display-1 text-bolder text-white bg-danger">Erro 404</h1>
            <h2>Página não encontrada!</h2>
            <p class="lead">
              Volte a tela anterior. Se o erro persistir, entrer em contato com nosso suporte.
            </p>
            <argon-button color="dark" variant="gradient" class="mt-4" @click="telaInicial"
              >Voltar</argon-button
            >
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>

<script>
import AppFooter from "@/examples/PageLayout/Footer.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { mapMutations } from "vuex";
export default {
  name: "SignupBasic",
  components: {
    AppFooter,
    ArgonButton,
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();
    document.getElementsByTagName("body")[0].classList.remove("bg-gray-100")
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
  },
  methods: {
    ...mapMutations(["toggleDefaultLayout"]),
    telaInicial() {
      // Criar if para verificar se usuário está logado. Caso sim, voltar para tela anterior, caso não levar para tela de login
      this.$router.push('/authentication/signin/basic');
    },
  },
};
</script>
