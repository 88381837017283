<script>
export default {
  name: "Authenticator",
  data() {
    return {
      clientId: '3344468379954702',
      redirectUri: window.location.origin + '/ecommerce/overview'
    };
  },
  mounted() {
    window.location.href = `https://auth.mercadolivre.com.br/authorization?response_type=code&client_id=${this.clientId}&redirect_uri=${this.redirectUri}`;
  }
};
</script>
