<template>
  <footer class="py-5 footer">
    <div class="container">
      <div class="row">
        <div class="mx-auto mt-2 mb-4 text-center col-lg-8">
          <a
            href="https://www.instagram.com/arewsistemas/"
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fab fa-instagram"></span>
          </a>
          <a
            href="https://web.facebook.com/arewsistemas/"
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fab fa-facebook"></span>
          </a>
          <a
            href="https://www.linkedin.com/company/arewsistemas"
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fab fa-linkedin"></span>
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=551144128530&text=ol%C3%A1,%20Estou%20no%20sistema%20ArewGE%20da%20Arew%20Sistemas.%20Peciso%20de%20um%20aux%C3%ADlio.%0A"
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fab fa-whatsapp"></span>
          </a>
          <a
            href="skype:[suportearew02]?chat"
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fab fa-skype"></span>
          </a>
          
        </div>
      </div>
      <div class="row">
        <div class="mx-auto mt-1 text-center col-8">
          <p class="mb-0 text-secondary">
            Todos os direitos reservados © {{ new Date().getFullYear() }} Arew Sistemas.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>
