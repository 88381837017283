<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-lg-6 mb-lg-0">
          <div
            class="text-sm text-center copyright text-muted"
            :class="this.$store.state.isRTL ? 'text-lg-end' : 'text-lg-start'"
          >
            ©
            {{ new Date().getFullYear() }}, Todos os direitos reservados por
            <a
              href="https://arewsistemas.com.br"
              class="font-weight-bold"
              target="_blank"
              >Arew Sistemas
            </a>.
          </div>
        </div>
        <div class="col-lg-6">
          <ul
            class="nav nav-footer justify-content-center justify-content-lg-end"
          >
            <li class="nav-item">
              <a
                href="https://898.tv/arewrapido"
                class="nav-link text-muted"
                target="_blank"
                >Team Viewer</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://api.whatsapp.com/send?phone=551144128530&text=ol%C3%A1,%20Estou%20no%20sistema%20ArewGE%20da%20Arew%20Sistemas.%20Peciso%20de%20um%20aux%C3%ADlio.%0A"
                class="nav-link text-muted"
                target="_blank"
                >WhatsApp</a
              >
            </li>
            <li class="nav-item">
              <a
                href="skype:[suportearew02]?chat"
                class="nav-link text-muted"
                target="_blank"
                >Skype</a
              >
            </li>
            <li class="nav-item">
              <a
                href="tel:1144123365"
                class="nav-link pe-0 text-muted"
                target="_blank"
                >Telefone</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>
