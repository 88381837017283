<template>
  <main class="main-content mt-0">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div
              class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto"
            >
              <div class="card card-plain py-lg-3">
                <div class="card-body text-center">
                  <h4 class="mb-0 font-weight-bolder">Olá {{ usuario }}</h4>
                  <p class="mb-4">Confirme o desbloqueio do seu cadastro no botão abaixo.</p>
                  <!-- <form role="form"> -->
                    <argon-alert v-show="apareceErroVazio" color="danger" class="my-4 mb-0">
                      <strong>Erro!</strong>
                      <p class="text-sm mx-2">{{msgpopup}}</p>
                    </argon-alert>

                    <argon-alert v-show="apareceSucesso" color="success" class="my-4 mb-0 translate-x-32">
                      <strong>Sucesso!</strong>
                      <p class="text-sm mx-2">{{msgpopup}}</p>
                    </argon-alert>
                    <div class="text-center">
                      <argon-button v-if="apareceSucesso !== true" color="dark" full-width size="lg" @click="confirmarCriacaoConta"
                        >Desbloquear acesso</argon-button
                      >
                    </div>
                  <!-- </form> -->
                </div>
              </div>
            </div>
            <div
              class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-dark h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="
                  background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/lock-ill.jpg');
                  background-size: cover;
                "
              >
                <span class="mask bg-dark opacity-6"></span>
                <h4
                  class="mt-5 text-white font-weight-bolder position-relative"
                >
                  Agradecemos sua escolha!
                </h4>
                <p class="text-white position-relative">
                  Aproveite o máximo possível do nosso sistema e entre em contato com a gente em caso de dúvidas.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonAlert from "@/components/ArgonAlert.vue";
import axios from "axios";

const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";
export default {
  name: "LockIllustration",
  components: {
    ArgonButton,
    ArgonAlert
  },
  data() {
    return {
      Pesquisa : '',
      apareceErroVazio : false,
      apareceSucesso : false,
      msgpopup : ''
    }
  },
  mounted() {
    this.Pesquisa = window.location.search;
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleDefaultLayout"]),
    rotaAposTime() {
      setTimeout(() => {
        window.location.replace(window.location.origin + '/authentication/signin/basic');
      }, 5000);
    },
    confirmarCriacaoConta() {
      axios.get('http://127.0.0.1:8080/usuario/confirmaEmailCadastro' + this.Pesquisa).then(response => {
        let data = response.data;
        console.log(data);
        if(data.success) {
          this.msgpopup = data.message;
          this.apareceErroVazio = false;
          this.apareceSucesso = true; 
          this.rotaAposTime();
        } else {
          this.msgpopup = "Tente novamente, se o erro persistir entre em contato com o suporte!";
          this.apareceErroVazio = true;
          this.apareceSucesso = false; 
        }
      }).catch(error => {
        this.msgpopup = error;
        this.apareceErroVazio = true;
        this.apareceSucesso = false;
      });
      
    }
  },
};
</script>
